.content {
  background: #ffffff;
  min-height: calc(100vh - 16px);
  .contentChildren {
    padding-bottom: 75px;
    padding-top: 75px;
  }

  :global(.btn:not(:disabled):not(.disabled):focus),
  :global(.btn:not(:disabled):not(.disabled):active:focus) {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  :global(.btn-primary:not(:disabled):not(.disabled):hover) {
    background-color: #2417ba;
    border-color: #2417ba;
  }
}
