@import '../../styles/variables.scss';

.bottomFooter {
  bottom: 0;
  @media (min-width: 576px) {
    height: 675px;
  }
  @media (max-width: 576px) {
    height: 795px;
  }
  width: 100vw;
  left: 0;
  background: url('/footer_background.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  align-items: center;
  position: relative;
  background-color: white;
  .footerContainer {
    margin-left: 13vw;
    justify-content: left;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    bottom: 170px;
  }
  .footerElement {
    min-width: 240px;
  }
  .footerElement a {
    display: block;
    color: $systemGreyDark !important;
    text-decoration: none;
  }
  .footerHeading {
    color: $sizzlyPrimaryDark;
    margin-bottom: 20px;
  }
  .footerDivideContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .footerDivider {
    border: 0;
    border-top: 2px solid;
    width: 100%;
    color: $sizzlyPrimaryDark;
  }
  .footerSocialMedia {
    width: 100%;
    border-bottom: 2px solid $sizzlyPrimaryDark;
    display: flex;
    justify-content: right;
    @media (max-width: 992px) {
      padding-right: 13vw;
    }
  }
  .footerSocialMedia a {
    margin: 5px;
  }
  .footerLogoContainer {
    display: flex;
    width: 100%;
  }
  .footerCopyright {
    font-size: 15px;
    align-self: center;
    display: flex;
    align-items: flex-end;
    color: $sizzlyPrimaryDark;
    width: 50vw;
    justify-content: right;
    @media (max-width: 992px) {
      padding-right: 13vw;
    }
  }
  .footerLogo {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-left: 10%;
    margin: 0 auto;
    @media (max-width: 992px) {
      margin-left: 13vw;
    }
  }

  .bottomElement {
    position: absolute;
    bottom: 0px;
    @media (min-width: 992px) {
      width: 75vw;
    }
    @media (max-width: 992px) {
      width: 100vw;
    }
    padding-bottom: 55px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
