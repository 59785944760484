@import '../../styles/variables.scss';
.orderType {
  color: $sizzlyPrimary;
  background: white;
  border-radius: 0;
  border-top: 2px solid $sizzlyPrimary;
  border-bottom: 2px solid $sizzlyPrimary;
  &:hover {
    background-color: $sizzlyPrimaryLight;
    color: $sizzlyPrimary;
  }
  &.orderTypeSelected {
    background: $sizzlyPrimary;
    color: white;
  }
  &:first-child {
    border-radius: $radiusBig 0 0 $radiusBig !important;
    border-left: 2px solid $sizzlyPrimary;
    border-right: 2px solid $sizzlyPrimary;
  }
  &:last-child {
    border-radius: 0 $radiusBig $radiusBig 0 !important;
    border-right: 2px solid $sizzlyPrimary;
    border-left: 2px solid $sizzlyPrimary;
  }
}

.oneOrderType {
  color: $sizzlyPrimary;
  background: white;
  border-radius: $radiusBig;
  border: 2px solid $sizzlyPrimary;
  &:hover,
  &.orderTypeSelected {
    background: $sizzlyPrimary;
    color: white;
  }
}

.expandedAccordionDivider {
  border-bottom: 1px solid $sizzlyPrimary;
}

.sizzlyColor {
  color: $sizzlyPrimary;
  &:hover {
    color: $sizzlyPrimary;
  }
}

.sizzlyColorBtn {
  color: $sizzlyPrimary;
  &:hover {
    color: $sizzlyPrimaryDark;
  }
}

.inputDecoration {
  border: 1px solid $sizzlyPrimary;
}

.tipButtonSelected {
  background: $sizzlyPrimary;
  color: white;
  &:hover {
    color: white;
  }
}

.tipButtonUnselect {
  border: 2px solid $sizzlyPrimary;
  color: $sizzlyPrimary;
  &:hover {
    background: $sizzlyPrimaryLight;
    color: $sizzlyPrimary;
  }
}

.maxHeightAccordion {
  max-height: 40vh;
  overflow-y: scroll;
}

.tipButton {
  height: 35px;
  width: 35px;
}

.sizzlyBackground {
  background-color: $sizzlyPrimary;
  color: white;
  &:hover {
    background: $sizzlyPrimaryDark;
  }
}

.sizzlyBtnBorderColored {
  color: $sizzlyPrimary !important;
  border: 2px solid $sizzlyPrimary;
  border-radius: $radiusBig;
}

.error {
  border: 2px solid $systemRed !important;
}

.tableInput {
  width: 50px;
}

.orderCommentButton:hover {
  text-decoration: underline $sizzlyPrimary;
}

.buttonMargin {
  margin-bottom: 7vh;
}

.shoppingBagInput {
  border-radius: $radiusSmall !important;
  border: 2px solid $sizzlyPrimary !important;
  color: black !important;
}

.phoneInput {
  width: 77% !important;
}

.coutryCodeInput {
  width: 35% !important;
}

.consetCheckbox {
  -ms-transform: scale(1.4); /* IE */
  -moz-transform: scale(1.4); /* FF */
  -webkit-transform: scale(1.4); /* Safari and Chrome */
  -o-transform: scale(1.4); /* Opera */
  transform: scale(1.4);
  margin-right: 5px;
}

.timeSelect {
  width: 30%;
}

.textRight {
  text-align: right;
}

.errorBorder {
  border: 2px solid $systemRed !important;
  color: $systemRed;
  + label {
    color: $systemRed !important;
  }
  &:focus {
    background: $systemRedOpacity !important;
    border: 2px solid $systemRed !important;
    color: $systemRed;
    + label {
      color: $systemRed !important;
    }
  }
}

.articleName {
  hyphens: auto;
  overflow-wrap: break-word;
}

.expressDiv {
  border: 1px solid white;
  border-radius: $radiusSmall;
  white-space: nowrap;
}

.expressText {
  margin-top: -28px;
  text-align: center;
  width: 90%;
  a {
    background-color: #d0d0d0;
    color: white !important;
  }
}

.divDivider {
  border-top: 1px solid white;
}

.differentiation {
  margin-top: -12px;
  position: absolute;
  width: 95%;
  text-align: center;
  a {
    background-color: #d0d0d0;
    color: white !important;
  }
}
