@import '../../styles/variables.scss';

.sizzlyColor {
  color: $sizzlyPrimary;
}

.cashPaymentAlignment {
  margin-left: 40px;
}

.roundedCorners {
  border-radius: 10px;
}
