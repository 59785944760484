@import '../../styles/variables.scss';

.shoppingButton {
  border: 1px solid white;
  background-color: $sizzlyPrimary;
  color: white;
  @media (max-width: 60em) {
    width: 90%;
  }
  @media (min-width: 60em) {
    width: 25%;
  }
  &:hover {
    background-color: $sizzlyPrimaryDark;
  }
}

.backgroundWhite {
  background: white;
}

.shoppingButton:hover {
  color: white;
}
