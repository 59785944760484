@import '../../styles/variables.scss';

.header {
  background: #d0d0d0;
}

.backgroundColor {
  background: #d0d0d0 !important;
}

.sizzlyColor {
  color: $sizzlyPrimary;
}

.backButton {
  width: 35px;
  height: 35px;
  padding: 0;
}

.sizzlyPrimaryButton {
  background-color: $sizzlyPrimary;
  color: white !important;
  @media (max-width: 60em) {
    width: 90%;
  }
  @media (min-width: 60em) {
    width: 30%;
  }
  &:hover {
    background-color: $sizzlyPrimaryDark;
  }
}
